import logo from './logo.svg';
import React, { Component} from 'react'
import './App.css';
import Spinner from 'react-bootstrap/Spinner'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ToastContainer, toast } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      carrito: localStorage.getItem('alestore_carrito') ? JSON.parse(localStorage.getItem('alestore_carrito')) : [],
      productos: [],
      loadingProductos: true,
      productosmongo: [],
      vercarrito: false,
      loadingPedido: false,
      validacioncarrito: [],
      datoscliente: localStorage.getItem('alestore_datoscliente') ? JSON.parse(localStorage.getItem('alestore_datoscliente')) : {},
      show: false,
      showfirst: true,
      showcerrado: false,
      filter: '',
      lightbox: false,
      lightboxsrc: '',
      config: {},
      loadingConfig: true,
      telefono: '', facebook: '', instagram: '', cerrado: 'no', portada: '', slides: [],
      productovariableseleccionado: false,
      showvariable: false,
      tipovisualizacion: 'default',
      toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
      pedidos: [],
      loadingPedidos: false,
      codigo_cliente: false,
      desbloquear_cantidad_minima: false,
      showmodalproveedor: false
    }
    this.handleChangeSugerencias = this.handleChangeSugerencias.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
    this.handleChangeState = this.handleChangeState.bind(this)
  }

  componentDidMount(){
    this.getProductos()
    injectStyle();
    this.getConfig()
  }

  handleChange = event => {
    this.setState({ filter: event.target.value });
};

  getConfig(){
    this.setState({ loadingConfig: true })
    return fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/sistemainventario-pfhcb/service/sistemainventario/incoming_webhook/configuracion`)
    .then(res => res.json())
    .then(respuesta => {
      this.setState({ loadingConfig: false })
      const showmodalcerrado = respuesta.cerrado.toLowerCase() === 'si' ? true : false
        this.setState({ config: respuesta, telefono: respuesta.telefono, facebook: respuesta.facebook, instagram: respuesta.instagram, portada: respuesta.portada, slides: respuesta.slides, cerrado: respuesta.cerrado ? respuesta.cerrado.toLowerCase() : 'no', showcerrado: showmodalcerrado })
    })
    .catch(error => {
      this.setState({ loadingConfig: false })
      console.log(error)
    })
  }

  handleChangeSugerencias(e){
    const { name, value } = e.target
    const { carrito } = this.state
    const cantidad = carrito.findIndex(pro => pro.id === name)
    if(carrito[cantidad]){
      carrito[cantidad].observaciones = value
    }
    return this.setState({ carrito: carrito })

  }
  agregaraCarrito(producto){
    const { carrito } = this.state
    let mostrarono = false
    const yaexiste = carrito.findIndex(pro => pro.id === producto._id['$oid'])
    if(carrito[yaexiste]){
      carrito[yaexiste].cantidad = (parseInt(carrito[yaexiste].cantidad) + 1).toString()
      toast(`Agregaste ${producto.Nombre} al carrito`)
    } else {

      if(producto.tipo==='variable'){
        mostrarono = true
        this.setState({ showvariable: true, productovariableseleccionado: producto })
      } else {
        const stock = producto.cantidad ? parseInt(producto.cantidad) : 1
        const check = 1 < parseInt(producto['Cantidad Minima']) ? producto['Cantidad Minima'] : '1'
        const cantidad = Math.min(stock, parseInt(check))
  
        carrito.push({
          _id: producto._id,
          Imagen: producto.Imagen,
          Descripcion: producto.Descripcion,
          id: producto._id['$oid'],
          Nombre: producto.Nombre,
          Precio: producto.Precio,
          cantidad: cantidad.toString()
        })
        toast(`Agregaste ${producto.Nombre} al carrito`)
      }
      
    }
    localStorage.setItem('alestore_carrito', JSON.stringify(carrito))
    
    return this.setState({ carrito: carrito })
  }

  handleChangeState(e){
    const { name, value } = e.target
    return this.setState({ [name]: value })
  }

  handleChangeUsuario(e){
    const { datoscliente } = this.state
    const { name, value } = e.target
    if(name==='rut'){
      datoscliente[name] = value.replace(/[^a-z0-9]/gi,'').toLowerCase()
    } else {
      datoscliente[name] = value
    }
    localStorage.setItem('alestore_datoscliente', JSON.stringify(datoscliente))
    return this.setState({ datoscliente: datoscliente }) 
  }

  ifInCarritoButton(producto){
    const { carrito } = this.state
    const cantidad = carrito.findIndex(pro => pro.id === producto._id['$oid'])
    if(cantidad > -1){
      return false
    }
    return <button onClick={() => this.agregaraCarrito(producto)} className="btn btn-sm btn-outline-danger">AGREGAR</button>
  }

  ifInCarrito2(id){
    const { carrito } = this.state
    const cantidad = carrito.findIndex(pro => pro.id === id)
    if(cantidad > -1){
      return 'seleccionado2'
    }
    return ''
  }

  ifInCarrito(id){
    const { carrito } = this.state
    const cantidad = carrito.findIndex(pro => pro.id === id)
    if(cantidad > -1){
      return 'seleccionado'
    }
    return ''
  }

  handleClose(){
    return this.setState({ show: false })
  }

  showmodal(){
    const { show, loadingPedido, datoscliente, config } = this.state
    return <Modal show={show} onHide={this.handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirma tus datos</Modal.Title>
    </Modal.Header>
    <Modal.Body>
<h4>{config.textodespachos}</h4>
<p>Starken - Pulman - Varmontt - Mena - CGS - PyG - CyR - CACEM</p>
<label className="form-control-label">Nombre y Apellido *</label>
<input name="nombres" className="form-control mb-2" value={datoscliente.nombres} onChange={this.handleChangeUsuario} />
<label className="form-control-label">RUT *</label>
<input name="rut" className="form-control mb-2" value={datoscliente.rut} onChange={this.handleChangeUsuario} />
<label className="form-control-label">FONO *</label>
<input name="fono" className="form-control mb-2" value={datoscliente.fono} onChange={this.handleChangeUsuario} />
<label className="form-control-label">Dirección o Ciudad de Envío *</label>
<input name="ciudad" className="form-control mb-2" value={datoscliente.ciudad} onChange={this.handleChangeUsuario} />
<label className="form-control-label">Agencia de Envíos</label>

  {
    config.agencias ? <select className="form-control" name="agencia" value={datoscliente.agencia} onChange={this.handleChangeUsuario} >
    <option value="">Seleccionar</option>
    {
      config.agencias.map((agencia,iage) => {
        return <option value={agencia.nombre} >{agencia.nombre}</option>
      }) 
    }
    </select> : <input name="agencia" className="form-control mb-2" value={datoscliente.agencia} onChange={this.handleChangeUsuario} />
  }


            </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={this.handleClose}>
        CERRAR
      </Button>
      {
          loadingPedido ? <Spinner animation="border" /> : <button onClick={() => this.enviarPedido()} className="btn btn-success">ENVIAR PEDIDO</button>
      }
    </Modal.Footer>
  </Modal>
  }

  async setQuantity(id,accion){
    const { carrito, productosmongo, desbloquear_cantidad_minima } = this.state
    const pos = await carrito.findIndex(pro => pro.id === id)
    const pro = await productosmongo.findIndex(pro => pro._id['$oid'] === id)

    let cantidad_incremento = parseInt(productosmongo[pro]['Cantidad Incremento'] ? productosmongo[pro]['Cantidad Incremento'] : 1)
    let cantidad_sumar = 1
    
    if(isNaN(cantidad_incremento) !== true) cantidad_sumar = cantidad_incremento

    if( pos > -1 ){
      if(accion==='mas'){
        const maximo = productosmongo[pro]['Cantidad Maxima'] ? parseInt(productosmongo[pro]['Cantidad Maxima']) : 1000
        
        const nuevostock = parseInt(carrito[pos].cantidad)+cantidad_sumar > maximo ? parseInt(carrito[pos].cantidad) : parseInt(carrito[pos].cantidad)+cantidad_sumar
        carrito[pos].cantidad = nuevostock.toString()
        console.log(nuevostock)
        console.log(carrito)
      } else if(accion==='menos') {
        let minimo = productosmongo[pro]['Cantidad Minima'] ? parseInt(productosmongo[pro]['Cantidad Minima']) : 1
        if(desbloquear_cantidad_minima === true){ 
          minimo = 1
        }
        // const stock = productosmongo[pro].cantidad ? parseInt(productosmongo[pro].cantidad) : minimo

        const nuevostock = parseInt(carrito[pos].cantidad)-cantidad_sumar < minimo ? false : parseInt(carrito[pos].cantidad)-cantidad_sumar
        if(!nuevostock){
          carrito.splice(pos,1)
        } else {
          carrito[pos].cantidad = nuevostock.toString()
        }
      }
    }
    localStorage.setItem('alestore_carrito', JSON.stringify(carrito))
    return this.setState({ carrito: carrito })
    

  }

  vaciarCarrito(){

    this.setState({ carrito: [] })
    localStorage.setItem('alestore_carrito', JSON.stringify([]))

  }
  ifInCarritoAcciones(id){
    const { carrito } = this.state
    const cantidad = carrito.findIndex(pro => pro.id === id)
    if(carrito[cantidad]){
      const total = parseInt(carrito[cantidad].cantidad)*(carrito[cantidad].Precio)
      return <Col xs={12}>
        <Row>
          <Col xs={8}>
          <h6 style={{ color: '#d50000', textAlign: 'left' }}>${total}</h6>
          </Col>
          <Col xs={4}>
          <div className="qty-input"><i className="less" onClick={() => this.setQuantity(id,'menos')} >-</i>
            <input style={{ marginBottom: 10 }} type="text" value={carrito[cantidad].cantidad}/><i className="more" onClick={() => this.setQuantity(id,'mas')} >+</i>
          </div>
          </Col>
          <Col xs={12}>
            <input name={id} onChange={this.handleChangeSugerencias} value={carrito[cantidad].observaciones} style={{ width:'100%', borderRadius:5, marginBottom:10, padding:'2px 8px', fontSize:17, border:'none' }} placeholder="Agregar aclaraciones" />
          </Col>
        </Row>
      </Col>
    }
    return ''
  }

  slider(){
    const { slides } = this.state
  
    if(slides.length > 0 ){
  
      return <Carousel style={{ width: '100%', marginBottom: 15 }}>
      {
        slides.map((slide,i) => {
          return <Carousel.Item key={`slide-${i}`}>
          <img
            className="d-block w-100"
            src={slide}
            alt="Third slide"
          />
        </Carousel.Item>
        })
      }
    </Carousel>
  
    }
  
    return false
  
  }

  mostrarProductos(){
    const { loadingProductos, datoscliente, loadingConfig, filter, productos, productosmongo, carrito, portada, vercarrito, tipovisualizacion } = this.state

    const lowercasedFilter = filter.toLowerCase();
    let productosFiltrados = []
    if(lowercasedFilter){
      productosFiltrados = productosmongo.filter(item => {
        return Object.keys(item).some(key =>
            item[key].toString().toLowerCase().includes(lowercasedFilter)
        );
    });
    }
    
    
    

    if(loadingProductos){
      return <div className="col-md-3 text-center cargando " style={{ textAlign: 'center' }}>
            <img src="LOGOTIPO.png" className="mb-3" style={{ width: '40%', marginBottom: 3 }} alt="logo" />
      <Spinner animation="border" />
  </div>
    }

    if(loadingConfig){
      return false
    }

    return <div style={{ width: "100%" }}>
<Row style={{ width: "103%"}} className="sticky text-white">
            <Col xs={4}>
            <img src="LOGOTIPO.png" className="mb-3" style={{ width: '80%', marginBottom: 0, marginTop: 3 }} alt="logo" />
            </Col>
            {/*<Col xs={3} style={{ textAlign: 'center', paddingTop: 5 }}>
            </Col>*/}
            <Col xs={5} style={{ paddingTop: 5 }} onClick={() => {

if(datoscliente.rut) this.obtenerPedidos(datoscliente.rut.replace(/[^a-z0-9]/gi,'').toLowerCase())
this.setState({ tipovisualizacion: 'pedidos' })
}}>
            <i className="fas fa-truck"  ></i>
            <h4>Seguimiento</h4>
            </Col>
            <Col xs={2} style={{ paddingTop: 5 }}>
            <i onClick={() => this.setState({ tipovisualizacion: 'carrito' })} className="fas fa-shopping-cart"></i>
            <h3 style={{ lineHeight: 1,  }}>{carrito.length}</h3>
            </Col>
          </Row>



{this.slider()}
{ portada ? <img className="imagenfield mb-3" alt="portada" src={portada} /> : false }

<div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 25 }} className="buscador">
{ filter.length > 0 ? <i style={{ color: '#d50000', marginBottom: 11, float: 'left', clear: 'both' }} className="fas fa-arrow-left" onClick={() => this.setState({ filter: '' })}></i> : false }
<input  placeholder="BUSCAR PRODUCTOS" className="form-control " value={filter} onChange={this.handleChange} />
<Button variant="primary" size="sm" onClick={()=>this.soyProveedor()} >SOY PROVEEDOR</Button>
</div>


{this.validacionesCarrito()}

{ filter ? productosFiltrados.map((producto,a) => {
              if(producto.tipo!=='variable'){
                if(!producto.cantidad) return false
                if(parseInt(producto.cantidad) < 1) return false
              }
              const ocultar = producto.Ocultar ? producto.Ocultar : 'no'
              if(ocultar.toLowerCase()==='si') return false
              if(producto.parent) return false
              return <Row className={this.ifInCarrito2(producto._id['$oid'])} key={`a${a}`} style={{ paddingLeft: 25, paddingRight: 25 }} >
                <Col xs={6} style={{ textAlign: 'left' }}>
                <h6  style={{ color: '#d50000', textAlign: 'left', marginBottom:0 }}><i className="fas fa-plus-circle mr-1"></i>{producto.Nombre}</h6>
                <p style={{ color: '#d50000', textAlign: 'left', fontSize: 13, marginBottom: 0 }}>{producto.Descripcion}</p>
                { producto.tipo!=='variable' ? <p style={{ textAlign: 'left', fontSize: 13, marginBottom: 0 }}><b>{producto.cantidad} en stock</b></p> : false }
                { this.ifInCarritoButton(producto)}
                </Col>
                <Col xs={2} style={{ padding:0 }}>
                <h6 style={{ color: '#d50000', fontSize: 13 }}>{producto.Precio ? `$${producto.Precio}`:'$0'}</h6>
                </Col>
                <Col xs={4}>

                  {  producto.Imagen ? <LazyLoadImage style={{ padding:5, marginBottom: 30, backgroundColor:'white', border:'2px solid #dadada', borderRadius: 10, width: '100%' }} src={producto.Imagen} onClick={() => this.setState({ lightboxsrc: producto.Imagen, lightbox: true })} key={producto.Imagen} alt={producto.Imagen} effect="blur" width={'100%'} /> : <div style={{ height: 130, marginBottom: 30, padding:5, backgroundColor:'white', border:'2px solid #dadada', borderRadius: 10, width: '100%' }} ></div> } 
                </Col>
                {this.ifInCarritoAcciones(producto._id['$oid'])}
              </Row>
            }) : false }
            

            {
              this.visualizacionByTipo()
            }


    </div>


  }


  visualizacionByTipo(){
    const { vercarrito, carrito, config, pedidos, productos, loadingPedidos, datoscliente, tipovisualizacion } = this.state

    if(tipovisualizacion==='carrito'){
      return <div>

 <Row style={{ width: '100%', padding: 30, paddingBottom: 80 }}>
    <Col xs={12} style={{ padding:0, textAlign: 'left' }}>
    <i style={{ color: '#d50000' }} className="fas fa-arrow-left" onClick={() => this.setState({ tipovisualizacion: 'default' })}></i>
    <h3>Tu pedido · {carrito.length} Productos</h3>
    { carrito.length > 0 ? <button className="btn btn-danger" onClick={()=>this.vaciarCarrito()} >Vaciar carrito</button> : <p>No tienes productos en el carrito...</p> }
    </Col>
{
            carrito.map((producto,a) => {

              return <Row className={this.ifInCarrito(producto._id['$oid'])} key={a} style={{ padding:0 }} >
                <Col xs={6} style={{ textAlign: 'left' }}>
                <h6  style={{ color: '#d50000', textAlign: 'left', marginBottom:0 }}><i className="fas fa-plus-circle mr-1"></i>{producto.Nombre}</h6>
                <p style={{ color: '#d50000', textAlign: 'left', fontSize: 13, marginBottom: 0 }}>{producto.Descripcion}</p>
                </Col>
                <Col xs={2} style={{ padding:0 }}>
                <h6 style={{ color: '#d50000', fontSize: 13 }}>{producto.Precio ? `$${producto.Precio}`:'$0'}</h6>
                </Col>
                <Col xs={4}>
                  {producto.Imagen ? <LazyLoadImage style={{ padding:5, marginBottom: 40, backgroundColor:'white', border:'2px solid #dadada', borderRadius: 10, width: '100%' }} src={producto.Imagen} onClick={() => this.setState({ lightboxsrc: producto.Imagen, lightbox: true })} key={producto.Imagen} alt={producto.Imagen} effect="blur" width={'100%'} /> : <div style={{ height: 130, marginBottom: 40, padding:5, backgroundColor:'white', border:'2px solid #dadada', borderRadius: 10, width: '100%' }} ></div>} 
                </Col>
                {this.ifInCarritoAcciones(producto._id['$oid'])}
              </Row>
            })
          }

  </Row>


      </div>
    } else if ( tipovisualizacion === 'default' ){

      return <div>
      <Accordion style={{ paddingBottom: 80 }}>
  {
    config.categorias ? config.categorias.map((seg,i) => {

      if(seg.activado === false){
        return false
      }
      
      if(!productos[seg.nombre]) console.log(seg.nombre)

      const productosordenados = productos[seg.nombre].sort((a,b) => {
        if(this.quitarAcentos(a.Nombre ? a.Nombre : '') < this.quitarAcentos(b.Nombre ? b.Nombre : '')) { return -1; }
        if(this.quitarAcentos(a.Nombre ? a.Nombre : '') > this.quitarAcentos(b.Nombre ? b.Nombre : '')) { return 1; }
        return 0;
      })

      return <Card>
      <Card.Header style={{ backgroundColor: 'white', border:'none', textAlign:'left' }}>
        <Accordion.Toggle as={Button} variant="link" style={{ width:'100%', textAlign:'left' }}  eventKey={i+1}>
          <h5 style={{ color: '#d50000',  textTransform:'none', marginBottom:0, padding:0 }}>{seg.nombre ? seg.nombre : 'Sin categoría' }</h5>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={i+1}>
        <Card.Body>
          {
            productosordenados.map((producto,a) => {
              if(producto.tipo!=='variable'){
                if(!producto.cantidad) return false
                if(parseInt(producto.cantidad) < 1) return false
              }
              const ocultar = producto.Ocultar ? producto.Ocultar : 'no'
              if(ocultar.toLowerCase()==='si') return false
              if(producto.parent) return false
              return <Row className={this.ifInCarrito(producto._id['$oid'])} key={a} style={{ padding:0 }} >
                <Col xs={6} style={{ textAlign: 'left' }}>
                <h6  style={{ color: '#d50000', textAlign: 'left', marginBottom:0 }}><i className="fas fa-plus-circle mr-1"></i>{producto.Nombre}</h6>
                <p style={{ color: '#d50000', textAlign: 'left', fontSize: 13, marginBottom: 0 }}>{producto.Descripcion}</p>
                { producto.tipo!=='variable' ? <p style={{ textAlign: 'left', fontSize: 13, marginBottom: 0 }}><b>{producto.cantidad} en stock</b></p> : false }
                { this.ifInCarritoButton(producto)}
                </Col>
                <Col xs={2} style={{ padding:0 }}>
                <h6 style={{ color: '#d50000', fontSize: 13 }}>{producto.Precio ? `$${producto.Precio}`:'$0'}</h6>
                </Col>
                <Col xs={4}>
                  { producto.Imagen ? <LazyLoadImage onClick={() => this.setState({ lightboxsrc: producto.Imagen, lightbox: true })} style={{ padding:5, marginBottom: 30, backgroundColor:'white', border:'2px solid #dadada', borderRadius: 10 }} key={producto.Imagen} alt={producto.Imagen} effect="blur" width={'100%'} src={producto.Imagen} /> : <div style={{ height: 130, marginBottom: 30, padding:5, backgroundColor:'white', border:'2px solid #dadada', borderRadius: 10, width: '100%' }} ></div>  } 
                </Col>
                {this.ifInCarritoAcciones(producto._id['$oid'])}
              </Row>
            })
          }
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    }) : false
  }
</Accordion>
    </div>

    } else if ( tipovisualizacion === 'pedidos' ){

      return <div style={{ padding: 20 }}>
      <div onClick={() => this.setState({ tipovisualizacion: 'default' })}><i style={{ color: '#d50000' }} className="fas fa-arrow-left" ></i> Volver al catálogo</div>
      <p>A través de tu rut puedes consultar las órdenes de compra generadas</p>
      <label className="form-control-label">TU RUT</label>
      <input name="rut" className="form-control mb-2" placeholder="RUT SIN PUNTO NI GUIONES" value={datoscliente.rut} onChange={this.handleChangeUsuario} />

      <button className="btn btn-success" onClick={()=>this.obtenerPedidos(datoscliente.rut)}>ACTUALIZAR ORDENES DE COMPRA</button>

      <hr />

      { loadingPedidos ? <Spinner animation="border" /> : false }


      <h3>{pedidos.length} Órdenes de compra</h3>

      <Accordion defaultActiveKey="0">
        {
          pedidos.map((pedido,ipe) => {

            return  <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={ipe+1}>
                ORDEN {pedido.id} · {pedido.fecha} 
                {this.etiquetaEstado(pedido.estado)}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={ipe+1}>
              <Card.Body>

<Row>
  <Col xs={4}>
  <span style={{ color: '#bfbfbf' }}>Teléfono</span>
  <p style={{ fontSize: 15 }}>{pedido.telefono}</p>
  </Col>

  <Col xs={4}>
  <span style={{ color: '#bfbfbf' }}>Ciudad</span>
  <p style={{ fontSize: 15 }}>{pedido.direccion}</p>
  </Col>

  <Col xs={4}>
  <span style={{ color: '#bfbfbf' }}>Agencia</span>
  <p style={{ fontSize: 15 }}>{pedido.agencia}</p>
  </Col>

  
  <Col xs={12}>
  <span style={{ color: '#bfbfbf' }}><i className="fas fa-truck"></i> Código de seguimiento</span>
  <p style={{ fontSize: 15 }}>{pedido.trackingcode ? pedido.trackingcode : 'En espera'}</p>
  </Col>

</Row>
  
                <table className="table tablapequeno">
                  <thead>
                    <tr>
                      <th>
                        
                      </th>
                      <th>Descripción</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pedido.productos.map((pro,ip) => {
                        return <tr key={`pro-${ip}`}>
                          <th>
                           { pro.Imagen ? <img style={{ width: 60 }} src={pro.Imagen} /> : false } 
                          </th>
                          <th>{pro.nombre}</th>
                          <th>{pro.cantidad}</th>
                          <th>{pro.total}</th>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          })
        }

</Accordion>

      </div>

    }



    

  }

  etiquetaEstado(estado){

    if(typeof estado === 'undefined') return <span className="spanrecibido">RECIBIDO</span>
    if(estado === 'en-preparacion') return <span className="spanpreparado">EN PREPARACIÓN</span>
    if(estado === 'enviado') return <span className="spanenviado">ENVIADO</span>

  }

  obtenerPedidos(rut){

    this.setState({ loadingPedidos: true })
    return fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/sistemainventario-pfhcb/service/sistemainventario/incoming_webhook/catalogo_getOrdenes?rut=${rut}`)
    .then(res => res.json())
    .then(res => {

      console.log(res)
      if(res.length < 1) toast.warn('No encontramos pedidos para este rut')
      return this.setState({ loadingPedidos: false, pedidos: res })

    })
    .catch(error => {
      this.setState({ loadingPedidos: false })
      return toast.error('No pudimos obtener la información', this.state.toaststyle)
    })
  }

  quitarAcentos(cadena){
    const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
    return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
  }

  async emitirPedido(){
    const { carrito, datoscliente, cerrado } = this.state

    this.setState({ loadingPedido: true, validacioncarrito: [] })
    console.log(carrito)
    return fetch('https://webhooks.mongodb-realm.com/api/client/v2.0/app/sistemainventario-pfhcb/service/sistemainventario/incoming_webhook/web_EmitirPedido', {
      method: 'POST',
      body: JSON.stringify({
        cliente: datoscliente,
        productos: carrito
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(res => {
      
    })
    .catch(error => console.log(error))
  }

  sanitizar(string){
    if('string' === typeof string ){
      let texto = string
      texto = texto.replace('&','')
      return texto.replace('#', '')
    }
    return string
  }

  async enviarPedido(){
    const { carrito, datoscliente, cerrado, telefono } = this.state

    if(cerrado.toLowerCase()==='si'){
      return alert('Catálogo cerrado, espera a que el catálogo esté abierto')
    }
    
    if(!datoscliente.nombres||!datoscliente.rut||!datoscliente.fono||!datoscliente.ciudad||!datoscliente.agencia){
      return alert("Todos los datos son requeridos llenar")
      }

    this.setState({ loadingPedido: true, validacioncarrito: [] })
    
    
    return fetch('https://webhooks.mongodb-realm.com/api/client/v2.0/app/sistemainventario-pfhcb/service/sistemainventario/incoming_webhook/web_checkStocks', {
      method: 'POST',
      body: JSON.stringify({
        cliente: datoscliente,
        productos: carrito
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(async res => res.json())
    .then(async res => {
      let mensajes = []
      const sinstock = res.filter(pro => pro.aprobado === false)

      if(sinstock.length > 0){
        sinstock.map(pro => {
          mensajes.push(`${pro.Nombre} solo tiene ${pro.inventario} unidades en stock y usted agregó ${pro.solicitado} al pedido`)
        })
        window.scrollTo(0, 0)
        return this.setState({ loadingPedido: false, show: false, validacioncarrito: mensajes })
      }

      let string = `Nombre y Apellido*%0A${this.sanitizar(datoscliente.nombres)}%0A%0A`;
      string += `RUT*%0A${this.sanitizar(datoscliente.rut)}%0A%0A`;
      string += `Fono*%0A${this.sanitizar(datoscliente.fono)}%0A%0A`;
      string += `Dirección o Ciudad de Envío*%0A${this.sanitizar(datoscliente.ciudad)}%0A%0A`;
      string += `Agencia de Envíos*%0A${this.sanitizar(datoscliente.agencia)}%0A%0A`;
      string += `Pedido:%0A%0A`;

      let totalpedido = 0
      carrito.map(pro => {
        const total = parseInt(pro.cantidad)*(pro.Precio)
        totalpedido = totalpedido + total
          string += `${pro.cantidad} x ${this.sanitizar(pro.Nombre)}%0A${ pro.observaciones ? `${this.sanitizar(pro.observaciones)}%0A` : '' }Subtotal = $${total} %0A%0A`;
      })

      string += `Total pedido: $${this.sanitizar(totalpedido)}`;

      const emitir = await this.emitirPedido()

      window.location.replace(`https://api.whatsapp.com/send?phone=+${telefono}&text=${string}`);
      localStorage.setItem('alestore_carrito', JSON.stringify([]))
      this.setState({ loadingPedido: false, validacioncarrito: [], carrito: [] })
      this.getProductos()
      return alert("Pedido enviado exitosamente")
      
    })
    .catch(error => {
      console.log(error)
      this.setState({ loadingPedido: false })
    })
  }

  validacionesCarrito(){
    const { validacioncarrito } = this.state
    if(validacioncarrito.length > 0){
      return <div className="dangerbox">
        <h3>¡No pudimos enviar tu pedido!</h3>
        <p>Modifica tu pedido y vuelve a confirmarlo</p>
        {
          validacioncarrito.map((mensaje,i) => {
            return <h6 key={`mensaje${i}`}><i className="fas fa-arrow-right"></i> {mensaje} </h6>
          })
        }
      </div>
    }
    return false
  }

  confirmarPedido(){
    return this.setState({ show: true})
  }

  showBotonPedido(){
    const { carrito, datoscliente } = this.state

    if(carrito.length > 0){

      let totalpedido = 0
      carrito.map(pro => {
        const total = parseInt(pro.cantidad)*(pro.Precio)
        totalpedido = totalpedido + total
      })

      return <div className="carrito">
<div style={{ padding: 0, display: 'inline-block', verticalAlign: 'middle', color: 'white', padding: '0px 10px' }} onClick={() => {

if(datoscliente.rut) this.obtenerPedidos(datoscliente.rut.replace(/[^a-z0-9]/gi,'').toLowerCase())
this.setState({ tipovisualizacion: 'pedidos' })
}}>
            
            <h4><i className="fas fa-truck"  ></i> Seguimiento</h4>
            </div>
<button onClick={() => this.confirmarPedido()} className="btn btn-success">CONFIRMAR PEDIDO ${totalpedido} </button>


      </div>
    }
  }

  getProductos(){
    return fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/sistemainventario-pfhcb/service/sistemainventario/incoming_webhook/verProductos`)
    .then(res => res.json())
    .then(respuesta => {

      const productos = respuesta.reduce((acc, curr) => {
        if(!acc[curr.Categoria]) acc[curr.Categoria] = []
        acc[curr.Categoria].push(curr)
        return acc
      },[])
      
        this.setState({ productos: productos, productosmongo: respuesta, loadingProductos: false})

    })
    .catch(error => console.log(error))
}

ifProductoVariable(){
  const { cerrado, productovariableseleccionado, productosmongo, showvariable, config } = this.state

  if(productovariableseleccionado){
    const productosrelacionados = productosmongo.filter(p => p.parent===productovariableseleccionado._id['$oid'])
    return <Modal show={showvariable} onHide={() => this.setState({ showvariable: false })} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
    <Modal.Body className="text-center">
      <h5 style={{ textAlign: 'center'}}>Selecciona cual de estas variedades deseas agregar al carrito</h5>
      {
        productosrelacionados.map((relacionado,ir) => {
          if(!relacionado.cantidad) return false
          if(parseInt(relacionado.cantidad) < 1) return false
          if(relacionado.Ocultar==='SI') return false
          return <div className="mb-2">
            { this.ifInCarritoButtonRelacionado(relacionado)}
            {this.ifInCarritoAccionesRelacionado(relacionado._id['$oid'])}
          </div>
        })
      }
            </Modal.Body>
  </Modal>
  }
}

ifInCarritoAccionesRelacionado(id){
  const { carrito } = this.state
    const cantidad = carrito.findIndex(pro => pro.id === id)
    if(carrito[cantidad]){
      const total = parseInt(carrito[cantidad].cantidad)*(carrito[cantidad].Precio)
      return <Col xs={12} style={{ paddingTop: 10, border: '1px solid #a6a6a6', borderRadius: 10 }}>
        <Row>
          <Col xs={8}>
          <h6 className="mb-0 text-left">{carrito[cantidad].Nombre}</h6>
          <h6 style={{ color: '#d50000', textAlign: 'left' }}>${total}</h6>
          </Col>
          <Col xs={4}>
          <div className="qty-input"><i className="less" onClick={() => this.setQuantity(id,'menos')} >-</i>
            <input style={{ marginBottom: 10 }} type="text" value={carrito[cantidad].cantidad}/><i className="more" onClick={() => this.setQuantity(id,'mas')} >+</i>
          </div>
          </Col>
          <Col xs={12}>
            <input name={id} onChange={this.handleChangeSugerencias} value={carrito[cantidad].observaciones} style={{ width:'100%', borderRadius:5, marginBottom:10, padding:'2px 8px', fontSize:17, border:'none' }} placeholder="Agregar aclaraciones" />
          </Col>
        </Row>
      </Col>
    }
    return ''
}

ifInCarritoButtonRelacionado(producto){
  const { carrito } = this.state
  const cantidad = carrito.findIndex(pro => pro.id === producto._id['$oid'])
  if(cantidad > -1){
    return false
  }
  return <button className="btn btn-outline-primary d-block m-auto" onClick={() => this.agregaraCarrito(producto)}>{producto.Nombre}</button>
}

  ifcerrado(){
    const { cerrado, showcerrado, config } = this.state

    if(cerrado.toLowerCase() === 'si'){
      return <Modal show={showcerrado} onHide={() => this.setState({ showcerrado: false })} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Body>
        <h5 style={{ textAlign: 'center'}}> {config.mensajecerrado ? config.mensajecerrado : 'CATÁLOGO CERRADO' } </h5>
              </Modal.Body>
    </Modal>
    }
  }

  soyProveedor(){
    return this.setState({ showmodalproveedor: true })
  }

  checkCodigoProveedor(){
    const { codigoproveedor } = this.state
    if(codigoproveedor==='ZGFuaWVsIHNlIGV'){
      this.setState({ desbloquear_cantidad_minima: true, showmodalproveedor: false  })
      return toast.success('Modalidad proveedor activada exitosamente', this.state.toaststyle)
    }
    return false
  }

  render(){
    const { showfirst, telefono, showmodalproveedor, lightbox, config, lightboxsrc } = this.state
    const cerradototal = config.cerradototal ? config.cerradototal.toLowerCase() : ''
    if(cerradototal==='si') return <div className="App">
      <header className="App-header">
      <img src="LOGOTIPO.png" className="mb-3" style={{ width: 200 }} alt="logo" />
      <h1><img src="reloj.png" style={{ width: 45 }} />{config.mensajecerrado}</h1>
      </header>
      <footer className="text-white">

      <h3>ALE STORE - PEDIDOS ONLINE </h3>
      <a href={`tel:${telefono}`} ><h3 style={{ color: 'white'}}>{telefono}</h3></a>
      <a style={{ display: 'block' }} href={config.facebook} target="_blank"><i className="fab fa-facebook"></i> Facebook</a>
      <a style={{ display: 'block' }} href={config.instagram} target="_blank"><i className="fab fa-instagram"></i> Instagram</a>
      <p>Desarrollado por ALE STORE</p>
      </footer>
    </div>

    return (
      <div className="App">
        <header className="App-header">

        <Modal show={showmodalproveedor} onHide={() => this.setState({ showmodalproveedor: false })} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
    <Modal.Body>
      <h5 style={{ textAlign: 'center'}}>Introduce tu código de proveedor</h5>
      <input className="form-control mb-3" name="codigoproveedor" onChange={this.handleChangeState} />
      <button className="btn btn-sm btn-primary" style={{ width: '100%' }} onClick={()=>this.checkCodigoProveedor()}>VERIFICAR</button>
            </Modal.Body>
  </Modal>

        <Modal show={showfirst} onHide={() => this.setState({ showfirst: false })} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
    <Modal.Body>
      <h5 style={{ textAlign: 'center'}}>{config.mensajespecial ? config.mensajespecial : 'SOLO VENTAS AL MAYOR' }</h5>
            </Modal.Body>
  </Modal>
  
  <Modal show={lightbox} onHide={() => this.setState({ lightbox: false })} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
  { lightbox ? <LazyLoadImage effect="blur" src={lightboxsrc} style={{ width: '100%', height: 'auto' }} /> : false }
  </Modal>
  
  
          {this.ifcerrado()}
          {this.ifProductoVariable()}
          {this.showmodal()}
          <ToastContainer 
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          />
          {this.showBotonPedido()}
          {this.mostrarProductos()}
        </header>

        <footer className="text-white">
        <h3>ALE STORE - PEDIDOS ONLINE</h3>
        <a href={`tel:${telefono}`} ><h3 style={{ color: 'white'}}>{telefono}</h3></a>
        <a style={{ display: 'block' }} href={config.facebook} target="_blank"><i className="fab fa-facebook"></i> Facebook</a>
        <a style={{ display: 'block' }} href={config.instagram} target="_blank"><i className="fab fa-instagram"></i> Instagram</a>
        <p>Desarrollado por ALE STORE</p>
        </footer>

      </div>
    )
  }
  
}

export default App;
